export const setSocketToken = (token: string) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('socketToken', token);
  }
};

export const clearSocketToken = () => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('socketToken');
  }
};

export const getSocketToken = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  return window.localStorage.getItem('socketToken');
};
