import { shift } from './shift';
import { user } from './user';
import { UserFlag } from './userFlag';

export enum ActionItemStatus {
  OPEN = 'Open',
  RESOLVED = 'Resolved',
}

export interface actionItem {
  assignedBy?: user; // staff
  assignedTo?: user; // staff
  createdAt?: string; // DateTime
  id?: number;
  priority?: number;
  resolvedAt?: string; // DateTime
  resolvedBy?: user;
  siblingTasks?: actionItem[];
  shift?: shift;
  type?: ActionItemType;
  updatedAt?: string; // DateTime
  userFlag?: UserFlag;
  uuid?: string;
  worker?: user;
}

export enum ActionItemType {
  // Action Board Items
  BACKGROUND_CHECK_ACCEPTED = 'BACKGROUND_CHECK_ACCEPTED',
  BACKGROUND_CHECK_OVERBOOKED = 'BACKGROUND_CHECK_OVERBOOKED',
  BLOCKED_WITH_UPCOMING = 'BLOCKED_WITH_UPCOMING',
  CHECK_IN_BOUNDS = 'CHECK_IN_BOUNDS',
  CHECK_OUT_BOUNDS = 'CHECK_OUT_BOUNDS',
  EARLY_CHECK_OUT = 'EARLY_CHECK_OUT',
  MISSING_CHECK_IN = 'MISSING_CHECK_IN',
  MISSING_CHECK_OUT = 'MISSING_CHECK_OUT',
  MISSING_I9_ACCEPTED = 'MISSING_I9_ACCEPTED',
  MISSING_I9_OVERBOOKED = 'MISSING_I9_OVERBOOKED',
  MISSING_T2 = 'MISSING_T2',
  MISSING_T24 = 'MISSING_T24',
  OVERBOOKED = 'OVERBOOKED',
  UNFILLED = 'UNFILLED',
  UNFILLED_PLAYBOOK_RUNNING = 'UNFILLED_PLAYBOOK_RUNNING',
  // Task Board Items
  FLAG_MISSED_CONFIRMATIONS = 'FLAG_MISSED_CONFIRMATIONS',
  FLAG_NEGATIVE_REVIEW = 'FLAG_NEGATIVE_REVIEW',
  FLAG_OTHER = 'FLAG_OTHER',
  FLAG_OUTDATED_PAPERWORK = 'FLAG_OUTDATED_PAPERWORK',
  FLAG_PROFILE_PHOTO = 'FLAG_PROFILE_PHOTO',
  FLAG_TERMS_VIOLATION = 'FLAG_TERMS_VIOLATION',
  POST_SHIFT_BACKGROUND_CHECK = 'POST_SHIFT_BACKGROUND_CHECK',
  POST_SHIFT_MISSING_EVEREE_INTEGRATION = 'POST_SHIFT_MISSING_EVEREE_INTEGRATION',
  POST_SHIFT_MISSING_I9 = 'POST_SHIFT_MISSING_I9',
  POST_SHIFT_PAPERWORK = 'POST_SHIFT_PAPERWORK',
}

export const ActionItemLabels = {
  [ActionItemType.BACKGROUND_CHECK_ACCEPTED]: 'Background Check',
  [ActionItemType.BACKGROUND_CHECK_OVERBOOKED]: 'Background Check OB',
  [ActionItemType.BLOCKED_WITH_UPCOMING]: 'Blocked with Upcoming',
  [ActionItemType.CHECK_IN_BOUNDS]: 'Check-In Bounds',
  [ActionItemType.CHECK_OUT_BOUNDS]: 'Check-Out Bounds',
  [ActionItemType.EARLY_CHECK_OUT]: 'Early Check-Out',
  [ActionItemType.FLAG_MISSED_CONFIRMATIONS]: 'Flag: Missing Confirmations',
  [ActionItemType.FLAG_NEGATIVE_REVIEW]: 'Flag: Negative Review',
  [ActionItemType.FLAG_OTHER]: 'Flag: Other',
  [ActionItemType.FLAG_OUTDATED_PAPERWORK]: 'Flag: Paperwork',
  [ActionItemType.FLAG_PROFILE_PHOTO]: 'Flag: Photo',
  [ActionItemType.FLAG_TERMS_VIOLATION]: 'Flag: Terms Violation',
  [ActionItemType.MISSING_CHECK_IN]: 'Missing Check-In',
  [ActionItemType.MISSING_CHECK_OUT]: 'Missing Check-Out',
  [ActionItemType.MISSING_I9_ACCEPTED]: 'Missing I9',
  [ActionItemType.MISSING_I9_OVERBOOKED]: 'Missing I9',
  [ActionItemType.MISSING_T2]: 'Missing T2',
  [ActionItemType.MISSING_T24]: 'Missing T24',
  [ActionItemType.OVERBOOKED]: 'Overbooked',
  [ActionItemType.POST_SHIFT_BACKGROUND_CHECK]: 'Background Check',
  [ActionItemType.POST_SHIFT_MISSING_EVEREE_INTEGRATION]: 'Missing Everee',
  [ActionItemType.POST_SHIFT_MISSING_I9]: 'Missing I9',
  [ActionItemType.POST_SHIFT_PAPERWORK]: 'Worker Agreement',
  [ActionItemType.UNFILLED]: 'Unfilled',
  [ActionItemType.UNFILLED_PLAYBOOK_RUNNING]: 'Playbook Running',
};

export const ShiftActionItems = [
  ActionItemType.BACKGROUND_CHECK_ACCEPTED,
  ActionItemType.BACKGROUND_CHECK_OVERBOOKED,
  ActionItemType.BLOCKED_WITH_UPCOMING,
  ActionItemType.CHECK_IN_BOUNDS,
  ActionItemType.CHECK_OUT_BOUNDS,
  ActionItemType.EARLY_CHECK_OUT,
  ActionItemType.MISSING_CHECK_IN,
  ActionItemType.MISSING_CHECK_OUT,
  ActionItemType.MISSING_T2,
  ActionItemType.MISSING_T24,
  ActionItemType.OVERBOOKED,
  ActionItemType.UNFILLED,
  ActionItemType.UNFILLED_PLAYBOOK_RUNNING
];

export const WorkerActionItems = [
  ActionItemType.BLOCKED_WITH_UPCOMING,
  ActionItemType.FLAG_MISSED_CONFIRMATIONS,
  ActionItemType.FLAG_NEGATIVE_REVIEW,
  ActionItemType.FLAG_OTHER,
  ActionItemType.FLAG_OUTDATED_PAPERWORK,
  ActionItemType.FLAG_PROFILE_PHOTO,
  ActionItemType.FLAG_TERMS_VIOLATION,
  ActionItemType.MISSING_I9_ACCEPTED,
  ActionItemType.MISSING_I9_OVERBOOKED,
  ActionItemType.POST_SHIFT_BACKGROUND_CHECK,
  ActionItemType.POST_SHIFT_MISSING_EVEREE_INTEGRATION,
  ActionItemType.POST_SHIFT_MISSING_I9,
  ActionItemType.POST_SHIFT_PAPERWORK,
];
