type SHIFT_MESSAGES_QUERY_ARGUMENTS = {
  limit: number;
};
export const SHIFT_MESSAGES_QUERY = (args: SHIFT_MESSAGES_QUERY_ARGUMENTS) => `
  query SHIFT_MESSAGES_QUERY($uuid: String) {
    shifts(uuid: $uuid) {
      retailerLocation {
        timezone
      }
      communicationBatches {
        createdAt
        name
        uuid
        meta
        sentBy {
          firstName
          lastName
        }
        pushPreview {
          content
          meta
        }
        pushCount
        textPreview {
          content
        }
        textCount
        projectedSendCount
        communications(limit:${args.limit}) {
          method
          to {
            firstName
            lastName
            uuid
            profileImg
          }
        }
      }
    }
  }
`;

export const PREVIEW_MESSAGE_USERS_QUERY = `
  query PREVIEW_MESSAGE_USERS_QUERY(
    $users: [String]
    $textBody: String
    $pushBody: String
    $shift: String
    $media: [String]
    $zipcodeMaxRange: Float
  ) {
    previewMessageUsers(
      to: $users
      textBody: $textBody
      pushBody: $pushBody
      shift: $shift
      media: $media
      zipcodeMaxRange: $zipcodeMaxRange
    ) {
      messages{
        code
        description
        user {
          uuid
        }
      }
      textPreview
      pushPreview
      validUsers {
        method
        user {
          uuid
        }
      }
      invalidUsers {
        method
        user {
          uuid
        }
      }
    }
  }
`;

export const COMMUNICATION_BATCH_QUERY = `
  query COMMUNICATION_BATCH_QUERY($uuid: String){
    communicationBatch(uuid: $uuid) {
      communications {
          method
          to {
            firstName
            lastName
            profileImg
            uuid
            totalShiftsCompleted
            lastShiftDate
          }
      }
    }
  }
`;
