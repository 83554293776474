import {
  CommonStore,
  commonStore,
  defaultCommonStoreState,
  fetchUsers,
  fetchUsersProps,
  Queries,
  sortGenerator,
} from 'helpers';
import { location, PageSizes, Prettify, SortState, user } from 'types';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import {
  AddPermissionsViews,
  UsersListActions,
  UsersSortOptions,
  UsersTabViews,
} from './UsersStore.helpers';

interface UsersStoreBase {
  activeTab: UsersTabViews;
  updateActiveTab: (view: UsersTabViews) => void;
  search: string;
  updateSearch: (value: string) => void;
  activeUser: user;
  updateActiveUser: (update: user) => void;
  activeLocation: location;
  updateActiveLocation: (update: location) => void;
  addPermissionsView: AddPermissionsViews;
  updateAddPermissionsView: (view: AddPermissionsViews) => void;
  totalUsers: number;
  updateTotalUsers: (total: number) => void;
  selectedUsers: Record<string, user>;
  selectedUsersArray: user[];
  selectedUsersUuidArray: string[];
  updateSelectedUsers: (user: user, action: 'add' | 'remove') => void;
  setSelectedUsers: (users: Record<string, user>) => void;
  clearSelectedUsers: () => void;
  selectionIsActive: boolean;
  setSelectionIsActive: (value: boolean) => void;
  prevSelectedRowIndex: number;
  setPrevSelectedRowIndex: (index: number) => void;
  activePageUsers: Record<string, user>;
  setActivePageUsers: (users: user[]) => void;
  selectAllPageUsers: () => void;
  updateSelectedAllFromServer: (
    queryVariables: fetchUsersProps,
  ) => Promise<void>;
  listAction: UsersListActions;
  setListAction: (action: UsersListActions) => void;
}

export type UsersStore = Prettify<
  CommonStore<typeof UsersSortOptions> & UsersStoreBase
>;

const defaultState = {
  activeTab: UsersTabViews.WORKERS,
  sort: {
    ...sortGenerator(SortState.NONE, UsersSortOptions),
    first_name: SortState.ASC,
  },
  pagination: {
    ...defaultCommonStoreState.pagination,
    pageSize: PageSizes.twentyFive,
  },
  totalUsers: 0,
  activeUser: {},
  activeLocation: {},
  addPermissionsView: AddPermissionsViews.LOCATIONS,
  selectedUsers: {},
  selectedUsersArray: [],
  selectedUsersUuidArray: [],
  selectionIsActive: false,
  prevSelectedRowIndex: -1,
  activePageUsers: {},
  listAction: UsersListActions.NONE,
};

const usersStore = (set, get): UsersStore => ({
  ...defaultCommonStoreState,
  ...commonStore(set),
  ...defaultState,
  updateActiveTab: view => set({ activeTab: view }),
  updateActiveUser: user =>
    set(prev => ({
      activeUser: {
        ...prev?.activeUser,
        ...user,
      },
    })),
  updateActiveLocation: location =>
    set({
      activeLocation: location,
    }),
  updateAddPermissionsView: view =>
    set({
      addPermissionsView: view,
    }),
  updateTotalUsers: total => set({ totalUsers: total }),
  updateSelectedUsers: (location, action) => {
    if (action === 'add') {
      set(prev => ({
        selectedUsers: {
          ...prev.selectedUsers,
          [location.uuid]: location,
        },
      }));
    } else {
      const currentUsers = get().selectedUsers;
      const newUsers = {};
      Object.keys(currentUsers).forEach(key => {
        if (key !== location?.uuid) newUsers[key] = currentUsers[key];
      });
      set({ selectedUsers: newUsers });
    }
  },
  setSelectedUsers: users => set({ selectedUsers: users }),
  clearSelectedUsers: () => set({ selectedUsers: {} }),
  setSelectionIsActive: value => set({ selectionIsActive: value }),
  setPrevSelectedRowIndex: index => set({ prevSelectedRowIndex: index }),
  setActivePageUsers: users => {
    const usersObject = users?.reduce(
      (acc, user) => {
        acc[user.uuid] = user;
        return acc;
      },
      {} as Record<string, user>,
    );
    set({ activePageUsers: usersObject });
  },
  selectAllPageUsers: () => set({ selectedUsers: get().activePageUsers }),
  updateSelectedAllFromServer: async (queryVariables: fetchUsersProps) => {
    const response = await fetchUsers(
      queryVariables,
      Queries.USER_SELECT_QUERY,
      'USER_SELECT_QUERY',
    );
    if (!response.hasErrors) {
      const { users } = response?.data || [];
      const usersMap = users?.reduce((acc, curr) => {
        acc[curr.uuid] = curr;
        return acc;
      }, {});
      set(prev => ({
        selectedUsers: { ...prev.selectedUsers, ...usersMap },
      }));
    }
  },
  setListAction: action => set({ listAction: action }),
});

export const useUsersStore = create(subscribeWithSelector(usersStore));

useUsersStore.subscribe(
  store => store.selectedUsers,
  users => {
    useUsersStore.setState({
      selectedUsersArray: Object.values(users),
      selectedUsersUuidArray: Object.keys(users),
    });
    if (Object.keys(users).length === 0) {
      useUsersStore.setState({ prevSelectedRowIndex: -1 });
    }
  },
);
