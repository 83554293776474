import {
  _gql,
  applyContext,
  fetchLocationBudgetsSubNodeProps,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
  redirectIfMaintenanceMode,
} from 'helpers';
import {
  address,
  GQL,
  LocationParking,
  LocationRelations,
  LocationStatus,
  LocationType,
  paging,
  PaymentContractTypeOptions,
  Prettify,
  ReflexResponse,
  RetailerCategory,
  RetailerSize,
  UserInitiatedLocationRelations,
} from 'types';

type fetchLocationsBaseProps = {
  market?: number;
  markets?: Array<number>;
  uuid?: string; // uuid
  uuids?: Array<string>; // uuid;
  limit?: number;
  isActive?: boolean;
  retailers?: Array<string>; // uuid;
  types?: Array<LocationType>;
  paymentTypes?: Array<PaymentContractTypeOptions>;
  retailerSizes?: Array<RetailerSize>;
  retailerCategories?: Array<RetailerCategory>;
  excludeUuids?: string[];
  withPermissionsFor?: string; // user uuid
  whereUserMissingPermissions?: string; // user uuid
  organization?: string; // org uuid
  status?: LocationStatus[];
  tags?: string[]; // tag uuid
  accountOwner?: string; // user uuid
};

type budgetUtilizationFilterProps = {
  budgetPeriod: string; // budget period uuid
  minUtilization?: number;
  maxUtilization?: number;
};

type fetchLocationsBudgetRelatedProps = {
  budgetUtilization?: budgetUtilizationFilterProps;
  hasUnapproved?: boolean;
  hasUnsetBudgetFor?: string; // budget period uuid
};

export type fetchLocationsProps = Prettify<
  fetchLocationsBaseProps &
    fetchLocationsBudgetRelatedProps &
    fetchLocationBudgetsSubNodeProps &
    paging
>;

export const fetchLocations = async (
  data: fetchLocationsProps,
  query = Queries.RETAILER_LOCATIONS_LIST_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = ['locations', 'locations_paging'],
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(
      GQL,
      contextOverride || 'RETAILER_LOCATIONS_LIST_QUERY',
    ),
    query,
    data,
    returnKey,
  });

export const fetchLocationsIds = async () => {
  const response = await _gql(
    applyContext(GQL, 'RETAILER_LOCATION_IDS_LIST_QUERY'),
    Queries.RETAILER_LOCATION_IDS_LIST_QUERY,
  )
  .then(redirectIfMaintenanceMode)
  .then(res =>
    res.json().catch(err => {
      throw new Error(err);
    }),
  );
  if (response.errors) {
    throw new Error(response.errors[0]);
  }
  return response.data;
};

export interface mutateCreateLocationProps {
  name: string;
  terms: string;
  retailer: string;
  address: Partial<address>;
  market?: number;
  mapImg?: string;
  mapHref?: string;
  flexSheetHref?: string;
  paymentType?: PaymentContractTypeOptions;
  paymentRate?: number;
  phoneNumber?: string;
  storeEmail?: string;
  compliance?: number | string;
  timezone?: string;
  organization?: string;
  status?: LocationStatus;
}

export const mutateCreateLocation = async (data: mutateCreateLocationProps) =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_LOCATION_MUTATION'),
    query: Mutations.CREATE_LOCATION_MUTATION,
    data,
    returnKey: 'location',
  });

export interface mutateUpdateLocationProps {
  locations: Array<string>; // uuid
  name?: string;
  market?: string;
  isActive?: boolean;
  parking?: LocationParking;
  phoneNumber?: string;
  storeEmail?: string;
  status?: LocationStatus;
  useV2FlexDetails?: boolean;
}

export const mutateUpdateLocation = async (data: mutateUpdateLocationProps) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_LOCATION_MUTATION'),
    query: Mutations.UPDATE_LOCATION_MUTATION,
    data,
    returnKey: 'location',
  });

export interface mutateLocationRelationsProps {
  locations?: string[]; // uuid
  users?: string[]; // uuid
  userStatus?: LocationRelations | UserInitiatedLocationRelations | 'None';
  locationStatus?: LocationRelations | UserInitiatedLocationRelations | 'None';
  note?: string;
}

export const mutateLocationRelations = async (
  data: mutateLocationRelationsProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_RELATION_MUTATION'),
    query: Mutations.UPDATE_RELATION_MUTATION,
    data,
    returnKey: 'updateUserRelation',
  });

export const mutateUpdateUserRelation = async (
  data: mutateLocationRelationsProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_RELATION_MUTATION'),
    query: Mutations.UPDATE_RELATION_MUTATION,
    data,
    returnKey: 'locations',
  });
