import { format } from 'date-fns';
import { ozNotification } from 'types';

enum NotificationTiming {
  LAST_15M = '< 15 min ago',
  LAST_HOUR = '< 1 hr ago',
  LAST_3_HOURS = '< 3 hrs ago',
  LAST_6_HOURS = '< 6 hrs ago',
  TODAY = 'Earlier Today',
  YESTERDAY = 'Yesterday',
}

export const getHelpfulTimestamp = (notification: ozNotification) => {
  const deltaMinutes = Math.floor(
    (Date.now() - new Date(notification.timestamp).getTime()) / 60000,
  );
  if (deltaMinutes <= 15) return NotificationTiming.LAST_15M;
  if (deltaMinutes <= 60) return NotificationTiming.LAST_HOUR;
  if (deltaMinutes <= 180) return NotificationTiming.LAST_3_HOURS;
  if (deltaMinutes <= 360) return NotificationTiming.LAST_6_HOURS;
  if (
    new Date(notification.timestamp).toDateString() ===
    new Date().toDateString()
  ) {
    return NotificationTiming.TODAY;
  }
  if (
    new Date(notification.timestamp).toDateString() ===
    new Date(Date.now() - 86400000).toDateString()
  ) {
    return NotificationTiming.YESTERDAY;
  }
  return format(new Date(notification.timestamp), 'MM/dd a:mm');
};
