import { _SHIFTS_PARAMS, _SHIFTS_VARIABLES } from './shift.helpers';

export const _SHIFT_BASIC_FIELDS = `
  id
  uuid
  status
  start
  end
`;

export const _SHIFTS_CORE = `
  ${_SHIFT_BASIC_FIELDS}
  acceptedCount
  acceptLimit
  cancelClassification
  cancelReason
  description
  duration
  flexibleEndTime
  flexibleStartTime
  isCancelled
  isRetailerApproved
  overbookLimit
  paymentType
  requestType
  retailerNotes
  visibleWorkerCount
  retailerLocation{
    uuid
    name
    type
    timezone
    compliance {
      breakRules {
        shiftMinutesToRequireBreak
      }
    }
    retailer {
      uuid
      name
      logoImage
    }
    market {
      name
      abbreviation
      id
    }
    address {
      zip
    }
  }
`;

export const _SHIFTS_WORKER_FIELDS = `
  firstName
  lastName
  preferredName
  uuid
  profileImg
  isI9Verified
  isW2PayproviderComplete
  onboardedBy {
    uuid
  }
  onboardingDate
  is1099PayproviderComplete
  isBackcheckComplete
  hoursThisWeek
  totalShiftsCompleted
  isCurrentlyWorking
  tier
  isFlagged
  workerShift {
    hasCompletedCompliancePaperwork
    confirmationStatus
    workershiftEvents {
      eventType
      expectedTimeRangeStart
      expectedTimeRangeEnd
      timestamp
      approved
      lat
      lon
      meta
      distanceApproved
    }
    status
    uuid
  }
  hasWorkedAtLocation
  notificationPreferences {
    isPushNotificationEnabled
  }
  isFavorite
  isApproved
  isBlocked
`;

export const _SHIFTS_WORKERS = `
  assignedWorkers{
    ${_SHIFTS_WORKER_FIELDS}
  }
  overbookedWorkers {
    ${_SHIFTS_WORKER_FIELDS}
  }
`;

export const _SHIFTS_TAGS = `
  tags {
    id
    name
    description
    isArchived
    isListVisible
  }
`;

export const _SHIFTS_NOTES = `
  notes {
    uuid
    content
    updatedAt
    type
    targetUuid
    createdBy {
      firstName
      lastName
    }
  }
`;

export const SHIFTS_UUID_QUERY = `
  query SHIFTS_UUID_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      uuid
    }
  }
`;

const _WORKER_SHIFT_EVENT_FIELDS = `
  eventType
  timestamp
  approved
  expectedTimeRangeStart
  expectedTimeRangeEnd
  noteCount
  lat
  lon
  userConfirmed
  distanceApproved
  verificationImg
  meta
  notes {
    uuid
    content
    createdBy {
      firstName
      lastName
    }
    createdAt
    extraData
  }
`;

export const _WORKER_FIELDS = `
  uuid
  firstName
  lastName
  preferredName
  workerShift {
    confirmationStatus 
    hasCompletedCompliancePaperwork
    status
    uuid
    workershiftEvents {
      ${_WORKER_SHIFT_EVENT_FIELDS}
    }
  }
  profileImg
  isW2PayproviderComplete
  is1099PayproviderComplete
  isI9Verified
  isBackcheckComplete
  onboardedBy {
    uuid
  }
  hoursThisWeek
  totalShiftsCompleted
  tier
  hasWorkedAtLocation
  notificationPreferences {
    isPushNotificationEnabled
  }
`;

export const _WORKER_FIELDS_CANCELLATION = `
  uuid
  firstName
  lastName
  preferredName
  workerShift {
    status
    uuid
    cancelClassification
    cancelNote
    cancellationTime
  }
  profileImg
`;

export const _NOTES_FIELDS = `
  uuid
  content
  updatedAt
  type
  targetUuid
  createdBy {
    firstName
    lastName
  }
`;

export const _LOCATION_FIELDS = `
  uuid
  name
  type
  timezone
  phoneNumber
  compliance {
    breakRules {
      shiftMinutesToRequireBreak
    }
  }
  market {
    name
    state
    id
  }
  address {
    zip
  }
  retailer {
    uuid
    name
    logoImage
  }
`;

export const _COMMUNICATION_BATCH_FIELDS = `
  createdAt
  name
  meta
  projectedSendCount
  sentBy {
    firstName
    lastName
  }
  pushCount
  textCount
`;

export const _PAYMENT_FIELDS = `
  status
  rate
  quantity
  type
  id
`;

export const _RETAILER_LOCATION_CANCEL_INFO_FIELDS = `
  payRate
  compliance {
    cancellationPayout {
      amounts {
        timing
        amount
        unit
      }
    }
  }
  terms {
    billingRate
    cancellationFee {
      amounts {
        timing
        amount
        unit
      }
    }
  }
`;
