import { FC, useState } from 'react';
import {
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';
import { useNotificationsStore } from 'utils/stores';
import { NotificationList } from './NotificationList';

export const NotificationPopover: FC = () => {
  const [unread, clearUnread, clearAlerts] = useNotificationsStore(s => [
    s.unread,
    s.clearUnread,
    s.clearAlerts,
  ]);
  const hasUnreadNotifications = Object.keys(unread).length > 0;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
    clearUnread();
    clearAlerts();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Popover onOpen={handleOpen} onClose={handleClose}>
      <PopoverTrigger>
        <Flex position="relative">
          <IconButton
            aria-label="Notifications"
            variant="transparent"
            color="main.white"
            icon={<Icon name={ICON_NAMES.bell} w={5} h={5} />}
          />
          {hasUnreadNotifications && (
            <Flex
              position="absolute"
              top={1}
              right={1}
              borderRadius="full"
              w="7px"
              h="7px"
              backgroundColor="red.500"
              borderWidth={1}
              borderColor="whiteAlpha.100"
            />
          )}
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody p={0} overflow="hidden">
            <NotificationList canFetch={isOpen} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
