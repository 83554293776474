import { cloneElement, useState } from 'react';
import { Flex, FlexProps, Tag, TagLabel } from '@chakra-ui/react';
import { noEmpties } from 'helpers';
import { useDataQueryProps, useGraphHelpers } from 'hooks';
import { Icon, ICON_NAMES } from 'icons';
import { SelectOption } from 'types';
import {
  commonPagingListProps,
  PagedList,
  SearchControl,
  SearchControlProps,
} from 'ui';
import { useDebounce } from 'use-debounce';

export enum SelectButtonVariant {
  DEFAULT = 'default',
  CHECK = 'check',
}

export interface SelectListProps<ObjectType, QueryVariables>
  extends useDataQueryProps<QueryVariables> {
  onSelect?: (object: ObjectType) => void;
  onRemove?: (option: SelectOption) => void;
  selectButtonText?: string;
  selectButtonVariant?: SelectButtonVariant;
  showSearch?: boolean;
  callout?: React.ReactElement;
  searchProps?: SearchControlProps;
  pageSize?: number;
  containerProps?: FlexProps;
  listContainerProps?: FlexProps;
  itemComponent?: React.ReactElement;
  currentValues?: SelectOption[];
  highlightSelectedValues?: boolean;
  selectedValues?: ObjectType[];
}

export const SelectList = <ObjectType, QueryVariables>({
  queryVariables,
  onSelect,
  selectButtonText,
  selectButtonVariant = SelectButtonVariant.DEFAULT,
  query,
  queryKey,
  showSearch = true,
  callout,
  searchProps,
  pageSize = 5,
  containerProps,
  listContainerProps,
  fetchFunction,
  dataKey,
  returnKey,
  pagingKey,
  itemComponent,
  currentValues,
  highlightSelectedValues = true,
  onRemove,
  selectedValues,
}: SelectListProps<ObjectType, QueryVariables>) => {
  const { commonOzPagingProps } = useGraphHelpers({
    pageSize,
  });
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 1000);

  const handleCurrentSelectionClick = (option: SelectOption) => {
    onRemove?.(option);
  };

  return (
    <Flex flexDirection="column" {...containerProps}>
      {showSearch && (
        <SearchControl
          containerProps={{ ml: 1, pr: 4, my: 4, width: '100%' }}
          inputProps={{ maxWidth: '100%', flexGrow: 1 }}
          handleClear={() => setSearch('')}
          value={search}
          handleUpdate={setSearch}
          label="Search"
          defaultOpen
          {...searchProps}
        />
      )}
      {callout && callout}
      {highlightSelectedValues && currentValues?.length > 0 && (
        <Flex mx={1} flexWrap="wrap" rowGap={1} columnGap={2} mb={2}>
          {currentValues?.map(option => (
            <Tag
              key={option?.value}
              cursor="pointer"
              gap={1}
              onClick={() => handleCurrentSelectionClick(option)}
            >
              <TagLabel>{option?.label}</TagLabel>
              <Icon name={ICON_NAMES.x} />
            </Tag>
          ))}
        </Flex>
      )}
      <Flex
        flexDirection="column"
        mx={-2}
        mt={2}
        flexGrow={1}
        minHeight={356}
        {...listContainerProps}
      >
        <PagedList<ObjectType, QueryVariables>
          canFetch
          fetchFunction={fetchFunction}
          queryVariables={noEmpties({
            orderBy: 'name',
            search: debouncedSearch,
            ...queryVariables,
          })}
          query={query}
          queryKey={queryKey}
          dataKey={dataKey}
          returnKey={returnKey}
          pagingKey={pagingKey}
          listColumns={() => [
            {
              id: dataKey,
              Cell: ({ row }) => {
                if (!itemComponent) return row.original[dataKey];
                return cloneElement(itemComponent, {
                  onSelect,
                  selectButtonText,
                  currentValues,
                  selectedValues,
                  selectButtonVariant,
                  ...row?.original,
                });
              },
            },
          ]}
          containerProps={{ flexGrow: 1 }}
          pagingListProps={commonPagingListProps()}
          paginationProps={commonOzPagingProps}
        />
      </Flex>
    </Flex>
  );
};
