import { _ACTIVE_PLAYBOOK_FIELDS, _SHIFTS_LIST_PLAYBOOK } from '../playbook';
import { _PAGING_PARAMS, _PAGING_VARIABLES } from '../_paging';
import {
  _COMMUNICATION_BATCH_FIELDS,
  _LOCATION_FIELDS,
  _NOTES_FIELDS,
  _PAYMENT_FIELDS,
  _RETAILER_LOCATION_CANCEL_INFO_FIELDS,
  _SHIFTS_CORE,
  _SHIFTS_NOTES,
  _SHIFTS_TAGS,
  _SHIFTS_WORKERS,
  _SHIFTS_WORKER_FIELDS,
  _SHIFT_BASIC_FIELDS,
  _WORKER_FIELDS,
  _WORKER_FIELDS_CANCELLATION,
} from './shift.common';
import { _SHIFTS_PARAMS, _SHIFTS_VARIABLES } from './shift.helpers';

export const SHIFT_ID_QUERY = `
  query SHIFT_QUERY (
    $uuid: String
    $id: Int
  ) {
    shifts (
      uuid: $uuid
      id: $id
    ) {
      id
      uuid
    }
  }
`;

///
// Shift Lists
export const SHIFTS_FLEX_BOARD_QUERY = `
  query SHIFTS_FLEX_BOARD_QUERY (${_SHIFTS_PARAMS}) {
    shifts (${_SHIFTS_VARIABLES}) {
      ${_SHIFTS_CORE}
      ${_SHIFTS_WORKERS}
      ${_SHIFTS_TAGS}
      ${_SHIFTS_NOTES}
      ${_SHIFTS_LIST_PLAYBOOK}
    }
  }
`;

export const SHIFT_PLAYBOOK_MODAL_QUERY = `
  query SHIFT_PLAYBOOK_MODAL_QUERY (${_SHIFTS_PARAMS}) {
    shifts (${_SHIFTS_VARIABLES}) {
      ${_SHIFT_BASIC_FIELDS}
      activePlaybook {
        ${_ACTIVE_PLAYBOOK_FIELDS}
      }
    }
  }
`;

///
// Shift Page

export const OZ_SHIFTS_BASIC_INFORMATION_QUERY = `
  query OZ_SHIFTS_BASIC_INFORMATION_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      id
      uuid
      createdAt
      isCancelled
      cancelClassification
      cancelReason
      status
      duration
      start
      end
      requestType
      requestedBy {
        uuid
        firstName
        lastName
      }
      isRetailerApproved
      approvedBy {
        uuid
        firstName
        lastName
      }
      retailerLocation {
        timezone
      }
    }
  }
`;

export const OZ_FULL_SHIFT_QUERY = `
  query OZ_SHIFTS_FULL_SHIFT_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      ${_SHIFTS_CORE}
      retailerNotes
      requiredBreak {
        breakLength
      }
      notes {
        uuid
        content
        updatedAt
        type
        targetUuid
        createdBy {
          firstName
          lastName
        }
      }
      assignedWorkers{
        location
        firstName
        lastName
        preferredName
        onboardedBy {
          uuid
        }
        workerShift {
          hasCompletedCompliancePaperwork
          confirmationStatus
          workershiftEvents {
            eventType
            timestamp
            approved
            expectedTimeRangeStart
            expectedTimeRangeEnd
            noteCount
            lat
            lon
            userConfirmed
            distanceApproved
            meta
            notes {
              uuid
              content
              createdBy {
                firstName
                lastName
              }
              createdAt
              extraData
            }
          }
          status
          uuid
        }
        uuid
        profileImg
        isW2PayproviderComplete
        is1099PayproviderComplete
        isI9Verified
        isBackcheckComplete
        hoursThisWeek
        totalShiftsCompleted
        tier
        hasWorkedAtLocation
        notificationPreferences {
          isPushNotificationEnabled
        }
      }
      overbookLimit
      overbookedWorkers {
        uuid
        firstName
        lastName
        preferredName
        onboardedBy {
          uuid
        }
        workerShift {
          confirmationStatus 
          hasCompletedCompliancePaperwork
          status
          uuid
          workershiftEvents {
            eventType
            timestamp
            approved
            expectedTimeRangeStart
            expectedTimeRangeEnd
            noteCount
            lat
            lon
            notes {
              uuid
              content
              createdBy {
                firstName
                lastName
              }
              createdAt
              extraData
            }
          }
        }
        uuid
        profileImg
        isW2PayproviderComplete
        is1099PayproviderComplete
        isI9Verified
        isBackcheckComplete
        hoursThisWeek
        totalShiftsCompleted
        tier
        hasWorkedAtLocation
      }
      payments {
        status
        rate
        quantity
        type
        id
      }
      workerRoles {
        id
        name
        icon
      }
      shiftWorkers {
        uuid
        viewCount
        messageCount
        status
        cancelNote
        cancelClassification
        hasCompletedCompliancePaperwork
        confirmationStatus
        worker {
          uuid
        }
      }
      activePlaybook{
        currentStep
        status
        playbook {
          uuid
          name
          globalWait
          steps {
            cohortName
            scheduledAt
            completedAt
            stepType
            timingPercentage
          }
        }
      }
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
    }
  }
`;

export const OZ_SHIFTS_EDIT_SHIFT_QUERY = `
  query OZ_SHIFTS_EDIT_SHIFT_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      id
      uuid
      start
      end
      description
      overbookLimit
      flexibleStartTime
      flexibleEndTime
      retailerLocation {
        timezone
      }
    }
  }
`;

export const OZ_SHIFT_QUERY = `
  query OZ_SHIFT_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      id
      uuid
      start
      end
      duration
      status
      paymentType
      flexibleStartTime
      flexibleEndTime
      retailerNotes
      description
      requiredBreak {
        breakLength
      }
      overbookLimit
      assignedWorkers {
        ${_WORKER_FIELDS}
      }
      overbookedWorkers {
        ${_WORKER_FIELDS}
      }
      payments {
        status
        rate
        quantity
        type
      }
      workerRoles {
        id
        name
        icon
      }
      retailerLocation {
        ${_LOCATION_FIELDS}
      }
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
      activePlaybook {
        ${_ACTIVE_PLAYBOOK_FIELDS}
      }
    }
  }
`;

export const OZ_SHIFT_DETAILS_QUERY = `
  query OZ_SHIFT_DETAILS_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      id
      uuid
      start
      end
      duration
      status
      paymentType
      flexibleStartTime
      flexibleEndTime
      retailerNotes
      description
      isRetailerApproved
      requiredBreak {
        breakLength
      }
      assignedWorkers {
        ${_WORKER_FIELDS}
      }
      overbookLimit
      overbookedWorkers {
        ${_WORKER_FIELDS}
      }
      payments {
        status
        rate
        quantity
        type
      }
      workerRoles {
        id
        name
        icon
      }
      retailerLocation {
        ${_LOCATION_FIELDS}
        ${_RETAILER_LOCATION_CANCEL_INFO_FIELDS} 
      }
      communicationBatches {
        ${_COMMUNICATION_BATCH_FIELDS}
      }
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
      requestType
      requestDetails
      activePlaybook {
        ${_ACTIVE_PLAYBOOK_FIELDS}
      }
      visibleWorkerCount
      messageCount
    }
  }
`;

export const OZ_SHIFT_NOTES_QUERY = `
  query OZ_SHIFT_NOTES_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      id
      uuid
      notes {
        ${_NOTES_FIELDS}
      }
    }
  }
`;

export const OZ_SHIFT_PAYMENTS_QUERY = `
  query OZ_SHIFT_PAYMENTS_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      id
      uuid
      payments {
        ${_PAYMENT_FIELDS}
      }
    }
  }
`;

export const OZ_SHIFT_EVENTS_QUERY = `
  query OZ_SHIFT_EVENTS_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      id
      uuid
      start
      end
      duration
      status
      paymentType
      requiredBreak {
        breakLength
      }
      assignedWorkers {
        ${_WORKER_FIELDS}
      }
      overbookLimit
      overbookedWorkers {
        ${_WORKER_FIELDS}
      }
      retailerLocation {
        ${_LOCATION_FIELDS}
      }
    }
  }
`;

export const SHIFT_AUDIT_LOGS_QUERY = `
  query SHIFT_AUDIT_LOG_QUERY(
    $shift: String
    $user: String
    $since: Date,
    $to: Date,
    ${_PAGING_PARAMS}
  ) {
    shiftAuditLogs(
      shift: $shift
      user: $user
      since: $since
      to: $to
      ${_PAGING_VARIABLES}
    ) {
        event
        timestamp
        user {
          firstName
          lastName
          isStaff
          uuid
        }
     }
  }
`;

///
// Location Pages

export const OZ_SHIFTS_FOR_LOCATION_QUERY = `
  query OZ_SHIFTS_FOR_LOCATION_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      ${_SHIFTS_CORE}
      ${_SHIFTS_WORKERS}
      ${_SHIFTS_TAGS}
      ${_SHIFTS_NOTES}
    }
  }
`;

export const OZ_SHIFTS_FOR_LOCATION_METRICS_QUERY = `
  query OZ_SHIFTS_FOR_LOCATION_METRICS_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      uuid
      workerRoles{
        name
      }
      start
    }
  }
`;

///
// User Pages

const _USER_SHIFT_CORE = `
  uuid
  start
  end
  status
  acceptedCount
  acceptLimit
  overbookLimit
  paymentType
  retailerLocation {
    name
    type
    timezone
    retailer {
      logoImage
      name
    }
  }
`;
const _USER_SHIFT_REVIEWS = `
  reviews {
    id
    prioritization
    recommended
    rating
    status
    reviewType
  }
`;
const _USER_SHIFT_ASSIGNED_AND_OVERBOOKED = `
  assignedWorkers {
    ${_WORKER_FIELDS}
  }
  overbookedWorkers {
    ${_WORKER_FIELDS}
  }
`;
const _USER_SHIFT_PAST_CANCELLATIONS = `
  cancelledWorkers {
    ${_WORKER_FIELDS_CANCELLATION}
  }
`;

export const OZ_SHIFTS_FOR_USER_QUERY = `
  query OZ_SHIFTS_FOR_USER_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      ${_USER_SHIFT_CORE}
    }
  }
`;

export const OZ_SHIFTS_FOR_USER_ACTIVE_UPCOMING_QUERY = `
  query OZ_SHIFTS_FOR_USER_ACTIVE_UPCOMING_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      ${_USER_SHIFT_CORE}
      ${_USER_SHIFT_ASSIGNED_AND_OVERBOOKED}
    }
  }
`;

export const OZ_SHIFTS_FOR_USER_VISIBILITY_QUERY = `
  query OZ_SHIFTS_FOR_USER_VISIBILITY_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      ${_USER_SHIFT_CORE}
    }
  }
`;

export const OZ_SHIFTS_FOR_USER_PAST_QUERY = `
  query OZ_SHIFTS_FOR_USER_PAST_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      ${_USER_SHIFT_CORE}
      ${_USER_SHIFT_REVIEWS}
    }
  }
`;

export const OZ_SHIFTS_FOR_USER_CANCELLED_QUERY = `
  query OZ_SHIFTS_FOR_USER_CANCELLED_QUERY(${_SHIFTS_PARAMS}) {
    shifts(${_SHIFTS_VARIABLES}) {
      ${_USER_SHIFT_CORE}
      ${_USER_SHIFT_PAST_CANCELLATIONS}
    }
  }
`;
