import { address } from './address';
import { graphCohort } from './cohort';
import { uuid } from './common';
import { communication } from './communication';
import { communicationBatch } from './communicationBatch';
import { apiCustomer, customer } from './customer';
import { document } from './document';
import { employmentRequirement } from './employmentRequirement';
import { location } from './location';
import { apiMarket, graphMarket } from './market';
import { noteType } from './note';
import { notificationRuleFull } from './notificationRules';
import { onboardingSchedule } from './onboardingSchedule';
import { organization } from './organization';
import { UserPaperwork } from './paperwork';
import { payment } from './payment';
import { LocationPermission, permissionsMap } from './permissions';
import { referral } from './referral';
import {
  apiRetailer,
  apiRetailerLight,
  apiRetailerLocationLight,
  graphRetailer,
  normalizedRetailerLight,
  retailer,
} from './retailer';
import { apiRole, role } from './role';
import { shift } from './shift';
import { tag } from './tag';
import { UserFlag } from './userFlag';
import { userNotificationPreferences } from './userNotificationPreferences';
import { workerBenefit } from './workerBenefit';
import { workershift } from './workershift';

export const ZIPCODE_DISTANCE_DEFAULT = {
  value: '25',
  label: '25',
};

export const ZIPCODE_DISTANCE_OPTIONS = [1, 2.5, 5, 10, 15, 20, 25, 30, 35];

export const REVIEW_WORKER_LINK = 'https://reflexhq.typeform.com/to/wILf80w7';
// #name=xxxxx&uid=xxxxx&sid=xxxxx

export enum UserStatus {
  LEAD = 'LEAD',
  QUALIFIED = 'QUALIFIED',
  REGISTERED = 'REGISTERED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NURTURE = 'NURTURE',
  DISMISSED = 'DISMISSED',
  OFFBOARDED = 'OFFBOARDED',
  REJECTED = 'REJECTED',
}

export enum UserTitle {
  STORE_MANAGER = 'STORE_MANAGER',
  ASSISTANT_MANAGER = 'ASSISTANT_MANAGER',
  KEY_HOLDER = 'KEY_HOLDER',
  DISTRICT_MANAGER = 'DISTRICT_MANAGER',
  REGIONAL_MANAGER = 'REGIONAL_MANAGER',
  CORP_CONTACT = 'CORP_CONTACT',
  BILLING = 'BILLING',
  OWNER = 'OWNER',
  STORE_TEAM = 'STORE_TEAM',
  VP_DIRECTOR = 'VP_DIRECTOR',
  OTHER = 'OTHER',
}

export const UserTitleLabel = {
  [UserTitle.STORE_MANAGER]: [UserTitle.STORE_MANAGER, 'Store Manager'],
  [UserTitle.ASSISTANT_MANAGER]: [
    UserTitle.ASSISTANT_MANAGER,
    'Assistant Manager',
  ],
  [UserTitle.KEY_HOLDER]: [UserTitle.KEY_HOLDER, 'Lead/Key Holder'],
  [UserTitle.DISTRICT_MANAGER]: [
    UserTitle.DISTRICT_MANAGER,
    'District Manager',
  ],
  [UserTitle.REGIONAL_MANAGER]: [
    UserTitle.REGIONAL_MANAGER,
    'Area/Regional Manager',
  ],
  [UserTitle.CORP_CONTACT]: [UserTitle.CORP_CONTACT, 'Corp. Contact'],
  [UserTitle.BILLING]: [UserTitle.BILLING, 'Billing'],
  [UserTitle.OWNER]: [UserTitle.OWNER, 'Owner'],
  [UserTitle.STORE_TEAM]: [UserTitle.STORE_TEAM, 'Store Team'],
  [UserTitle.VP_DIRECTOR]: [UserTitle.VP_DIRECTOR, 'VP/Director'],
  [UserTitle.OTHER]: [UserTitle.OTHER, 'Other'],
};

export enum UserAttribute {
  DORMANT = 'dormant',
}

export enum UserRole {
  WORKER = 'WORKER',
  RETAILER = 'RETAIL',
  STAFF = 'STAFF',
  SYSTEM = 'SYSTEM',
  SUPPORT = 'SUPPORT',
}

export const UserSortOptions = {
  first_name: 'Name',
  created_at: 'Join Date',
} as const;

export enum WorkerTierOptions {
  R1 = 'R1',
  R2 = 'R2',
  R3 = 'R3',
  R4 = 'R4',
  N1 = 'N1',
  N2 = 'N2',
  N3 = 'N3',
  N4 = 'N4',
  G1 = 'G1',
  G2 = 'G2',
  G3 = 'G3',
  G4 = 'G4',
}

export enum WorkerHistoryOptions {
  HAS_WORKED = 'WORKED',
  HAS_NOT_WORKED = 'NOT_WORKED',
}

export enum WorkerBookedOptions {
  IS_BOOKED = 'BOOKED',
  NOT_BOOKED = 'NOT_BOOKED',
}

export enum WorkerExperienceDuration {
  SHORT = 'SHORT',
  MEDIUM = 'MEDIUM',
  LONG = 'LONG',
  EXTENDED = 'EXTENDED',
  UNKNOWN = 'UNKNOWN',
}

export enum UserFitFor {
  FRONT_OF_HOUSE = 'FOH',
  BACK_OF_HOUSE = 'BOH',
  LUXURY = 'LUXURY',
}

export const UserFitForLabel = {
  [UserFitFor.FRONT_OF_HOUSE]: 'Front of House',
  [UserFitFor.BACK_OF_HOUSE]: 'Back of House',
  [UserFitFor.LUXURY]: 'Luxury',
};

export type apiSkillItem = {
  id: number;
  name: string;
};

export type apiInterviewItem = {
  id: number;
  question: number;
  question_text: string;
  answer: string;
};

export type apiExperienceItem = {
  id: number;
  retailer: number;
  start: string;
  end: string;
  duration_months?: number;
  retailer_name: string;
  accolades: string;
  roles?: Array<number>;
  role?: number; // DEPRECATED
  location?: string;
  name?: string;
  duration?: WorkerExperienceDuration;
  details?: string;
};

export interface apiUserLight {
  id?: number;
  uuid?: string;
  first_name?: string;
  last_name?: string;
  preferred_name?: string;
  pronouns?: string;
  phone_number?: string;
  profile_img?: string;
  location?: string;
  language?: string;
  tagline?: string;
  email?: string;
  erit_id?: string | null;
  is_text_subscribed?: boolean | null;
}

export type graphExperience = {
  id?: string;
  name?: string;
  location?: string;
  start?: string; // DateTime
  end?: string; // DateTime
  retailer?: graphRetailer;
  duration?: WorkerExperienceDuration;
  roles?: Array<role>;
};

export type UserLocationPermissions = {
  location?: location;
  permissionType: LocationPermission;
}[];

export type graphUserLight = {
  uuid?: uuid;
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  email?: string;
  profileImg?: string;
};

export type user = {
  aboutMe?: string;
  activatedBy?: user;
  activeFlags?: Array<UserFlag>;
  address?: address;
  adminFor?: Array<customer>;
  allBrands?: retailer[];
  approvedBy?: Array<location>;
  approvedLocations?: location[];
  assignedTier?: WorkerTierOptions;
  attributes?: Record<UserAttribute, unknown>;
  birthdate?: string; // DateTime
  blockedBy?: Array<location>;
  canScheduleCall?: boolean;
  cohort?: graphCohort;
  createdAt?: string; // DateTime
  customer?: customer;
  dateJoined?: string; // DateTime
  email?: string;
  employmentRequirements?: employmentRequirement[];
  eritId?: string;
  experience?: Array<graphExperience>;
  favoritedBy?: Array<location>;
  firstName?: string;
  fitFor?: {
    name?: UserFitFor;
    id?: number;
  }[];
  flags?: Array<UserFlag>;
  hasMultipleBrands?: boolean;
  hasOutstandingBudgetApprovals?: boolean;
  hasWorkedAtLocation?: boolean;
  hoursThisWeek?: number;
  id?: number;
  is1099PayproviderComplete?: boolean;
  isActive?: boolean;
  isApproved?: boolean;
  isBackcheckComplete?: boolean;
  isBlocked?: boolean;
  isCurrentlyWorking?: boolean;
  isFavorite?: boolean;
  isFlagged?: boolean;
  isI9Verified?: boolean;
  isRecentlyActive?: boolean;
  isRetail?: boolean;
  isStaff?: boolean;
  isSupport?: boolean;
  isValidated?: boolean;
  isW2PayproviderComplete?: boolean;
  isWorker?: boolean;
  language?: string;
  lastActivity?: string; // DateTime
  lastLogin?: string; // DateTime
  lastName?: string;
  lastShiftAtLocation?: shift;
  lastShiftAtLocationsWithoutRelation?: Array<shift>;
  lastShiftDate?: string; // Date
  location?: string;
  locationPermissions?: UserLocationPermissions; // graph from locationPermissions
  locationPermissionsMap?: permissionsMap; // reduced map from locationPermissions
  locationsWithAccess?: location[];
  locationsWorkedAt?: Array<location>;
  markets?: Array<graphMarket>;
  messagesReceived?: Array<communication>;
  messagesSent?: Array<communication>;
  nextShift?: workershift;
  notes?: Array<noteType>;
  notificationPreferences?: userNotificationPreferences;
  notificationRules?: Array<notificationRuleFull>;
  onboardedBy?: user;
  onboarding?: string;
  onboardingDate?: string; // 2022-11-10T17:30:00+00:00
  organization?: organization;
  paperworkI9Link?: string;
  password?: string;
  payments?: Array<payment>;
  permissionTypes?: LocationPermission[]; // reduced map of all permissions
  phoneNumber?: string;
  platformExperienceBrands?: retailer[];
  preferredName?: string;
  prioritizationAvg?: number;
  profileColor?: string;
  profileImg?: string;
  pronouns?: string;
  ratingAvg?: number;
  ratingAvgActual?: number;
  ratingCount?: number;
  referralCode?: referral;
  referrer?: referral;
  requiresPasswordUpdate?: boolean;
  role?: UserRole;
  roles?: Array<role>;
  scheduledOnboarding?: onboardingSchedule;
  sentCommunicationBatches?: communicationBatch[];
  shifts?: Array<workershift>;
  signedDocuments?: Array<document>;
  skills?: Array<userSkill>;
  status?: UserStatus;
  tagline?: string;
  tags?: Array<tag>;
  tardyScore?: number; // Float between 0 and 1
  textingLink?: string;
  tier?: WorkerTierOptions;
  title?: string;
  totalShiftsAtLocation?: number;
  totalShiftsCompleted?: number;
  updatedAt?: string; // DateTime
  urgentCancelScore?: number; // Float between 0 and 1
  userBlocked?: Array<location>;
  userPaperwork?: Array<UserPaperwork>;
  uuid?: string;
  workerBenefits?: workerBenefit[];
  workerEndorsements?: userFeedbackCount[];
  workerGrowthAreas?: userFeedbackCount[];
  workerShift?: workershift;
};

export type apiReview = {
  id?: number;
  created_at?: string;
  from_name?: string;
  retailer?: apiRetailerLight;
  feedback?: string;
};

export type apiUser = {
  id: number;
  uuid: string;
  user_type: string | UserRole;
  first_name: string;
  last_name: string;
  preferred_name?: string;
  pronouns?: string;
  phone_number: string;
  profile_color: string | ProfileColor;
  profile_img: string;
  location: string;
  language: string;
  tagline: string;
  about_me: string;
  total_shifts_completed: number;
  total_experience_months: number;
  percent_accept_rate: number;
  percent_cancel_rate: number;
  percent_ignore_rate: number;
  rating_avg: number;
  rating_count: number;
  skills: Array<apiSkillItem>;
  interview: Array<apiInterviewItem>;
  experience: Array<apiExperienceItem>;
  email: string;
  all_brands: Array<apiRetailerLight>;
  percent_recommend?: number;
  reviews: Array<apiReview>;
  approved_locations: Array<apiRetailerLocationLight>;
  status: UserStatus;
  onboarding: Record<string, unknown>;
  birthdate: string;
  address: address;
  referrer: string | null;
  referral_code: string | null;
  onboarding_date: string | null;
  requires_password_update?: boolean;
  customer?: Array<Partial<apiCustomer>>;
  markets?: Array<apiMarket>;
  erit_id?: string;
  is_text_subscribed?: boolean;
  can_schedule_call?: boolean;
};

export type userDetails = {
  fullName: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  pronouns?: string;
  phone: string;
  image: string;
  location: string;
  language: string;
  tagline?: string;
  about: string;
  email?: string;
  isNew?: boolean;
  birthdate?: string;
  address?: address;
  referrer?: string | null;
  referral_code?: string | null;
  onboarding_date?: string | null;
  eritId?: string | null;
  isTextSubscribed?: boolean | null;
  canScheduleCall?: boolean;
};

export type graphUserDetails = {
  uuid?: string;
  firstName?: string;
  isCurrentlyWorking?: boolean;
  lastName?: string;
  location?: string;
  profileImg?: string;
  tier?: WorkerTierOptions;
};

export interface userStats {
  overallRating?: number;
  overallRatingCount?: number;
  numTotalShifts?: number;
  percentAcceptRate?: number;
  percentCancelRate?: number;
  percentIgnoreRate?: number;
  percentRecommendRate?: number;
}

export type userSkill = {
  id?: number;
  name?: string;
};

export type userRating = {
  id: number;
  label: string;
  rating: number;
};

export type userReview = {
  id: number;
  fromName: string;
  fromRetailerId: number;
  text: string;
  rating?: number;
};

export type userReviews = Array<userReview>;

export type userHistoryItem = {
  id: number;
  retailerId: number;
  retailerName: string;
  startDate: Date;
  endDate: Date;
  durationMonths: number;
  accolades: string;
  roles: Array<number>;
  roleId: number; // FUTURE: roles
  location?: string;
  name?: string;
  duration?: WorkerExperienceDuration;
  details?: string;
};

export type userExperience = {
  about: string;
  numTotalExperienceMonths: number;
  skills: Array<userSkill>;
  brands: Array<number>; // FUTURE: api
  allBrands: Array<normalizedRetailerLight>;
  history: Array<userHistoryItem>;
};

export type userInterviewAnswer = {
  id: number;
  questionId: number;
  questionText: string;
  answerText: string;
};

export enum ProfileColor {
  GREEN = 'green',
  PURPLE = 'purple',
  NAVY = 'navy',
  YELLOW = 'yellow',
}

export type userFeedbackCount = {
  name?: string;
  count?: number;
  priority?: number;
  icon?: string;
};

export interface userMeta {
  role: string | UserRole;
  color: string | ProfileColor;
}

export interface userOnboarding {
  hasClickedWelcome?: boolean;
  hasScheduledCall: boolean; // FUTURE: remove this. use onboarding_date instead
  confirmed_onboarding?: boolean;
  onboarded_by_uuid?: string; // uuid
  instagram?: string;
  linkedin?: string;
  tiktok?: string;
  twitter?: string;
  skipSocial?: boolean;
  history?: string;
  retailExperience?: boolean;
  whyReflex?: string;
  can_schedule_call?: boolean;
  zipcode?: string;
}

export interface normalizedUser {
  id?: number;
  uuid?: string;
  name?: string;
  birthdate?: string;
  status?: UserStatus;
  details?: userDetails;
  stats?: userStats;
  reviews?: userReviews;
  interview?: Array<userInterviewAnswer>;
  experience?: userExperience;
  meta?: userMeta;
  retailer?: apiRetailer;
  approvedLocations?: Array<apiRetailerLocationLight>;
  onboarding?: Partial<userOnboarding>;
  requires_password_update?: boolean;
  customer?: Array<apiCustomer>;
  markets?: Array<apiMarket>;
}

export interface normalizedUserLight {
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  pronouns?: string;
  phone: string;
  image: string;
  location: string;
  language: string;
  tagline: string;
  email: string;
  eritId?: string | null;
  isTextSubscribed?: boolean | null;
  canScheduleCall?: boolean;
}

// LATER: remove this altogether
// right now, is tied to quite a few places
// but we can eventual ditch in favor of *role* everywhere
export type normalizedExperienceRole = apiRole;

export type normalizedExperienceRoles = Array<normalizedExperienceRole>;

export const IS_NEW_LIMIT = 1;

export type UpdateUserFn = (data: Partial<apiUser>) => Promise<void>;

export type UpdateUserExperienceFn = ({
  data,
  id,
}: {
  data: Partial<userHistoryItem>;
  id: number;
}) => Promise<apiExperienceItem>;

export type AddUserExperienceFn = (
  data: Partial<userHistoryItem>,
) => Promise<apiExperienceItem>;

export type DeleteUserExperienceFn = (id: number) => Promise<void>;
export type AddUserSkillFn = (id: number) => Promise<void>;
export type DeleteUserSkillFn = (id: number) => Promise<void>;
export type RefreshUserFn = () => void;

export type UpdateUserOnboardingFn = (
  data: Partial<userOnboarding>,
) => Promise<void>;

export type UpdateUserMarketsFn = (markets: Array<number>) => Promise<void>;

export type UpdateUserAddressFn = ({
  data,
  id,
}: {
  data: Partial<address>;
  id: number;
}) => Promise<address>;

export type AddUserAddressFn = (data: Partial<address>) => Promise<address>;

export type extendedUserDetails = Partial<userDetails> & {
  uuid: uuid;
  status: UserStatus;
  lastLogin: string; // "2022-04-11T20:23:00.639440+00:00"
  profileImg: string;
  phoneNumber: string; // "+15125555555"
  isWorker: boolean;
  isRetail: boolean;
  isI9Verified?: boolean;
  isW2PayproviderComplete: boolean;
  is1099PayproviderComplete: boolean;
  isBackcheckComplete: boolean;
  hoursThisWeek: number;
  totalShiftsCompleted: number;
  isCurrentlyWorking?: boolean;
  lastShiftDate: string; // yyyy-mm-dd
  tier: WorkerTierOptions;
  shifts?: Array<workershift>;
  textingLink: string;
  workerShift?: workershift;
  favoritedBy?: Array<{
    uuid?: uuid;
  }>;
  approvedBy?: Array<{
    uuid?: uuid;
  }>;
  blockedBy?: Array<{
    uuid?: uuid;
  }>;
};

export type worker = Record<uuid, Partial<user>>;

// FUTURE: get rid of this
export const SKILL_ICON_MAP = {
  1: 'skillapproachable',
  2: 'skillcustomerfacing',
  3: 'skillcommunication',
  4: 'skillgiftwrapping',
  5: 'skilltrustworthy',
  6: 'skillhustler',
  7: 'skillteamplayer',
  8: 'skilldependable',
  9: 'skillpunctual',
  10: 'skillheavylifter',
  11: 'skillfolder',
  12: 'skillstylist',
  13: 'skillinteriordesign',
  14: 'skillpointofsale',
};

// FUTURE: get rid of this
export const STATE_OPTIONS = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};
