import { FC, useState } from 'react';
import { Flex, IconButton, Text } from '@chakra-ui/react';
import {
  getHelpfulTimestamp,
  mutateOzNotificationReceipt,
  mutateOzNotificationReceiptProps,
} from 'helpers';
import { useDataMutation } from 'hooks';
import { Icon, ICON_NAMES } from 'icons';
import { useRouter } from 'next/router';
import { DataKeys, ozNotification, OzNotificationReceiptStatus } from 'types';
import {
  getNotificationIcon,
  getNotificationRoute,
} from 'utils/helpers/notifications';

export const NotificationItem: FC<ozNotification> = notification => {
  const [isProcessing, setIsProcessing] = useState(false);

  const { mutate: dismissNotification } =
    useDataMutation<mutateOzNotificationReceiptProps>(
      mutateOzNotificationReceipt,
      [DataKeys.OZ_NOTIFICATIONS],
    );

  const handleDismiss = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsProcessing(true);
    await dismissNotification.mutateAsync({
      uuids: [notification?.uuid],
      status: OzNotificationReceiptStatus.DISMISSED,
    });
    setIsProcessing(false);
  };

  const route = getNotificationRoute(notification);
  const router = useRouter();
  const handleNavigation = () => {
    if (!route) return;
    router.push(route);
  };

  return (
    <Flex
      width="100%"
      gap={2}
      py={3}
      px={2}
      cursor="pointer"
      onClick={handleNavigation}
    >
      <Flex pt={0.5}>
        <Icon name={getNotificationIcon(notification)} />
      </Flex>
      <Flex flexDirection="column">
        <Flex gap={2} align="center">
          <Text variant="header-sm">{notification?.title}</Text>
          <Text variant="body-xs" color="blackAlpha.600">
            {getHelpfulTimestamp(notification)}
          </Text>
        </Flex>
        <Text variant="body-sm" color="oz.secondary" lineHeight={1.2} mt={0.5}>
          {notification?.subtext}
        </Text>
      </Flex>
      <Flex>
        <IconButton
          key={notification?.uuid}
          aria-label="Dismiss"
          icon={
            <Icon
              name={isProcessing ? ICON_NAMES.loader : ICON_NAMES.check}
              sx={
                isProcessing && {
                  '@keyframes rotate': {
                    '0%': { transform: 'rotate(0deg)' },
                    '100%': { transform: 'rotate(360deg)' },
                  },
                  animation: 'rotate 2s linear infinite',
                }
              }
            />
          }
          variant="transparent"
          size="sm"
          onClick={handleDismiss}
        />
      </Flex>
    </Flex>
  );
};
