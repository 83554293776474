import { isPast } from 'date-fns';
import {
  activePlaybook,
  playbook,
  PlaybookAction,
  PlaybookStatus,
  playbookStep,
  PlaybookStepStatus,
  shift,
} from 'types';

export const getPlaybookName = (playbook: playbook, includeVersion = true) => {
  if (!playbook?.name) return '';
  const canShowVersion = playbook?.version && includeVersion;
  return `${playbook?.name}${canShowVersion ? ` v${playbook?.version}` : ''}`;
};

export const getPlaybookStats = (playbook: activePlaybook) => {
  const nextStepIndex =
    playbook?.steps?.findIndex(
      step => step?.uuid === playbook?.nextStep?.uuid,
    ) || 0;

  const completedSteps = playbook?.steps?.filter(step =>
    [PlaybookStepStatus.COMPLETE, PlaybookStepStatus.SKIPPED].includes(
      step?.status,
    ),
  );

  const lastStepUuid =
    completedSteps?.length > 0
      ? completedSteps[completedSteps.length - 1]?.uuid
      : null;

  const lastStepIndex = playbook?.steps?.findIndex(
    step => step?.uuid === lastStepUuid,
  );

  return {
    lastStepIndex,
    lastStepNumber: lastStepIndex + 1,
    nextStepIndex,
    nextStepNumber: nextStepIndex + 1,
    totalSteps: playbook?.steps?.length,
    completedSteps,
  };
};

export const getPlaybookMessagingSteps = (playbook: activePlaybook) => {
  const messagingSteps =
    playbook?.steps?.filter(
      step => step?.action === PlaybookAction.SEND_PUSH,
    ) || [];

  const nonCompleteMessagingSteps = messagingSteps?.filter(
    step =>
      step?.status === PlaybookStepStatus.QUEUED ||
      (step?.status === PlaybookStepStatus.SKIPPED &&
        !isPast(new Date(step?.processTime))),
  );

  return {
    messagingSteps,
    messagingStepsUuids: messagingSteps?.map(step => step?.uuid),
    nonCompleteMessagingSteps,
    nonCompleteMessagingStepsUuids: nonCompleteMessagingSteps?.map(
      step => step?.uuid,
    ),
  };
};

export const getShiftPlaybookInfo = (shift: shift) => {
  const { nextStepIndex, ...stats } = getPlaybookStats(shift?.activePlaybook);
  const hasActivePlaybook =
    !!shift?.activePlaybook && Object.keys(shift?.activePlaybook).length > 0;
  const hasPlaybookStarted = nextStepIndex > 0;
  const playbookIsStarting = [PlaybookStatus.PENDING].includes(
    shift?.activePlaybook?.status,
  );
  const playbookIsComplete = [PlaybookStatus.COMPLETE].includes(
    shift?.activePlaybook?.status,
  );
  const playbookIsCancelled = [PlaybookStatus.STOPPED].includes(
    shift?.activePlaybook?.status,
  );

  return {
    hasActivePlaybook,
    hasPlaybookStarted,
    playbookIsStarting,
    playbookIsComplete,
    playbookIsCancelled,
    ...stats,
  };
};

export interface getVirtualPlaybookStepStatusProps {
  step: playbookStep;
  isProcessing?: boolean;
}

export const getVirtualPlaybookStepStatus = ({
  step,
  isProcessing,
}: getVirtualPlaybookStepStatusProps): PlaybookStepStatus => {
  if (!step?.status) return PlaybookStepStatus.INACTIVE;

  // Push step status are virtual because of messaging templates
  if (step?.action !== PlaybookAction.SEND_PUSH) {
    if (isProcessing) return PlaybookStepStatus.PROCESSING;
    return step?.status;
  }

  const isComplete = step?.status === PlaybookStepStatus.COMPLETE;
  if (isComplete) return PlaybookStepStatus.COMPLETE;

  const isHistory = isPast(new Date(step?.processTime));
  if (
    isHistory &&
    [PlaybookStepStatus.SKIPPED, PlaybookStepStatus.INACTIVE].includes(
      step?.status,
    )
  )
    return PlaybookStepStatus.SKIPPED;

  if (isProcessing) return PlaybookStepStatus.PROCESSING;

  const hasTemplate =
    !!step?.messagingTemplate && Object.keys(step?.messagingTemplate)?.length;
  if (!hasTemplate) return PlaybookStepStatus.INACTIVE;
  const hasActiveTemplate = step?.messagingTemplate?.active;
  if (!hasActiveTemplate) return PlaybookStepStatus.INACTIVE;

  const isPaused = step?.status === PlaybookStepStatus.PAUSED;
  if (isPaused) return PlaybookStepStatus.PAUSED;

  return step?.status;
};
