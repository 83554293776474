export const CANCEL_CURRENT_PLAYBOOK_MUTATION = `
  mutation CANCEL_CURRENT_PLAYBOOK_MUTATION(
    $shift: String
    ) {
    cancelCurrentPlaybook(shift: $shift) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const SET_ACTIVE_PLAYBOOK_MUTATION = `
  mutation SET_ACTIVE_PLAYBOOK_MUTATION($locations: [String], $playbook: String) {
    setActivePlaybook(locations: $locations, playbook: $playbook) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const FAST_FORWARD_PLAYBOOK_MUTATION = `
  mutation FAST_FORWARD_PLAYBOOK_MUTATION(
    $shift: String
    $step: String
  ) {
    fastForwardPlaybook(shift: $shift, step: $step) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const UPDATE_PLAYBOOK_STEP_MUTATION = `
  mutation UPDATE_PLAYBOOK_STEP_MUTATION(
    $playbookSteps: [String]
    $status: String
    $processTime: DateTime
  ) {
    updatePlaybookStep(
      playbookSteps: $playbookSteps
      status: $status
      processTime: $processTime
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;
