import { createPersistedStore } from '../store.helpers';
import { colorOptions, patternOptions } from './settingsGeneral.helpers';

export type Color = keyof typeof colorOptions;
export type Pattern = keyof typeof patternOptions;

interface SettingsStore {
  defaultShiftFilters: Record<string, unknown>;
  shiftsConfirmationMode: boolean;
  receiveOzNotifications: boolean;
  updateShiftsConfirmationMode: (setting: boolean) => void;
  themeColor: Color;
  updateThemeColor: (color: Color) => void;
  themePattern?: keyof typeof patternOptions;
  updateThemePattern: (pattern: Pattern) => void;
  updateReceiveOzNotifications: (setting: boolean) => void; 
}

const settingsStore = (set): SettingsStore => ({
  defaultShiftFilters: {},
  shiftsConfirmationMode: false,
  receiveOzNotifications: true,
  updateShiftsConfirmationMode: (setting: boolean) =>
    set({
      shiftsConfirmationMode: setting,
    }),
  updateReceiveOzNotifications: (setting: boolean) =>
    set({
      receiveOzNotifications: setting,
    }),
  themeColor: 'navy.500',
  updateThemeColor: (color: Color) =>
    set({
      themeColor: color,
    }),
  themePattern: null,
  updateThemePattern: (pattern: Pattern) =>
    set({
      themePattern: pattern,
    }),
});

export const useSettingsStore = createPersistedStore<SettingsStore>(
  settingsStore,
  { name: 'reflex-settings' },
);
