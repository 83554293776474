import { isWithinInterval } from 'date-fns';
import {
  convertShiftWorkerToWorkerShift,
  getFormattedPaymentValues,
  getShiftBonusPayment,
} from 'helpers';
import {
  LocationType,
  shift,
  ShiftPaymentType,
  user,
} from 'types';
import { MAX_HOURS_WARNING } from 'utils/types/configs';

export {
  convertShiftWorkerToWorkerShift,
  getFormattedPaymentValues,
  getShiftBonusPayment,
};

export const getShiftPageTitle = (shift?: shift, isLoading = true) => {
  if (isLoading) return 'Loading Flex';
  if (!shift) return '';
  return `Flex at ${shift?.retailerLocation?.retailer?.name}`;
};

export const isShiftNow = (shift?: shift) => {
  if (!shift || !shift?.start || !shift?.end) return false;
  return isWithinInterval(new Date(), {
    start: new Date(shift?.start),
    end: new Date(shift?.end),
  });
};

export const isShiftWaitlist = (shift?: shift) => {
  if (!shift) return false;
  return shift?.retailerLocation?.type === LocationType.WAITLIST;
};

export const hasAssignedOrOverbookedWorkers = (shift?: shift) => {
  if (!shift) return false;
  return !!shift?.assignedWorkers?.length || !!shift?.overbookedWorkers?.length;
};

export const getAssignedAndOverbookedInfo = (shift?: shift) => {
  const { assignedWorkers, overbookedWorkers, overbookLimit } = shift || {};
  const assignedWorkerCount = assignedWorkers?.length;
  const overbookedWorkerCount = overbookedWorkers?.length;
  const hasAssignedWorker = assignedWorkerCount > 0;
  const assignedWorker = assignedWorkers?.[0];
  const overbookedCount = overbookedWorkerCount;
  const hasOverbookedWorkers = overbookedCount > 0;
  const overbookedWorkerOne = overbookedWorkers?.[0];
  const overbookedWorkerTwo = overbookedWorkers?.[1];
  const theRestOfOverbookedWorkers = !overbookedWorkers
    ? []
    : [...overbookedWorkers.slice(1)];
  const overbooksRemaining = overbookLimit - overbookedCount;
  return {
    overbookLimit,
    assignedWorkerCount,
    overbookedWorkerCount,
    hasAssignedWorker,
    assignedWorker,
    overbookedCount,
    hasOverbookedWorkers,
    overbookedWorkerOne,
    overbookedWorkerTwo,
    overbooksRemaining,
    theRestOfOverbookedWorkers,
  };
};

export const getAssignedWorkerFlags = (
  shift: shift,
  user?: user,
) => {
  const { paymentType } = shift || {};
  const isWaitlist = shift?.retailerLocation?.type === LocationType.WAITLIST;
  const isW2 = paymentType == ShiftPaymentType.TYPE_W2;

  const needsBackgroundCheck = !user?.isBackcheckComplete;
  const needsI9 = !isWaitlist && isW2 && !user?.isI9Verified;
  const needsW2PayProvider =
    !isWaitlist && isW2 && !user?.isW2PayproviderComplete;
  const needs1099PayProvider =
    !isWaitlist && !isW2 && !user?.is1099PayproviderComplete;
  const needsPaperwork = !user?.workerShift?.hasCompletedCompliancePaperwork;
  const needsOnboarding = !user?.onboardedBy || !user?.onboardingDate;
  const needsMobile = !user?.notificationPreferences;
  const isNoPush = !user?.notificationPreferences?.isPushNotificationEnabled;
  const isOverHours = user?.hoursThisWeek > MAX_HOURS_WARNING;
  const isRepeatAtStore = user?.hasWorkedAtLocation;
  const isNewToStore = !user?.hasWorkedAtLocation;
  const isNewToPlatform = user?.totalShiftsCompleted === 0;
  const isFlagged = user?.isFlagged;

  const hasRequirementProblem = (() => {
    if (isWaitlist) return false;
    if (isW2)
      return [
        needsBackgroundCheck,
        needsI9,
        needsW2PayProvider,
        needsPaperwork,
      ].some(x => x);
    return [needsBackgroundCheck, needs1099PayProvider, needsPaperwork].some(
      x => x,
    );
  })();

  return {
    isWaitlist,
    isW2,
    needsBackgroundCheck,
    needsI9,
    needsW2PayProvider,
    needs1099PayProvider,
    needsPaperwork,
    needsOnboarding,
    needsMobile,
    isNoPush,
    isOverHours,
    isRepeatAtStore,
    isNewToStore,
    isNewToPlatform,
    isFlagged,
    hasRequirementProblem,
    hasAnyFlag: [
      needsBackgroundCheck,
      needsI9,
      needsW2PayProvider,
      needs1099PayProvider,
      needsPaperwork,
      needsOnboarding,
      needsMobile,
      isNoPush,
      isOverHours,
      isNewToStore,
      isNewToPlatform,
      isRepeatAtStore,
      isFlagged,
      hasRequirementProblem,
      needsOnboarding,
    ].some(x => x),
  };
};
