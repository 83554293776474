import { fetchUsersProps } from 'helpers';
import { SelectOption, UserRole, UserStatus, WorkerTierOptions } from 'types';
import {
  convertEnumToFilterOptions,
  dateCreatedParser,
  dateParser,
} from 'utils/helpers/filters';
import { CreatedFilters, TimingFilters } from 'utils/types/filters';

export const UserStatusOptions = convertEnumToFilterOptions(UserStatus)?.filter(
  o =>
    [
      UserStatus.ACTIVE,
      UserStatus.REGISTERED,
      UserStatus.INACTIVE,
      UserStatus.NURTURE,
      UserStatus.OFFBOARDED,
      UserStatus.REJECTED,
      UserStatus.DISMISSED,
    ].includes(o?.value as UserStatus),
);

export interface UsersFiltersState {
  usersAcquisitionCampaign: SelectOption[];
  usersActivatedBy: SelectOption[];
  usersCanScheduleCall: SelectOption[];
  usersCreatedAt: SelectOption[];
  usersExcludeDormant: SelectOption[];
  usersFlags: SelectOption[];
  usersHasAddress: SelectOption[];
  usersHasExperience: SelectOption[];
  usersHasProfileImage: SelectOption[];
  usersHasWorked: SelectOption[];
  usersIsBooked: SelectOption[];
  usersIsFlagged: SelectOption[];
  usersMarkets: SelectOption[];
  usersMetabaseQuestions: SelectOption[];
  usersNotWorkedSince: SelectOption[];
  usersOnboardedBy: SelectOption[];
  usersPriorityMax: SelectOption[];
  usersPriorityMin: SelectOption[];
  usersRatings: SelectOption[];
  usersRetailer: SelectOption[];
  usersRetailerLocation: SelectOption[];
  usersShiftsCompleteMax: SelectOption[];
  usersShiftsCompleteMin: SelectOption[];
  usersStaffMembers: SelectOption[];
  usersStatus: SelectOption[];
  usersTags: SelectOption[];
  usersTagsMissing: SelectOption[];
  usersTiers: SelectOption[];
  usersWorkedSince: SelectOption[];
  usersZipcode: SelectOption[];
  usersZipcodeMaxDistance: SelectOption[];
  usersZipcodeMinDistance: SelectOption[];
}

export interface UsersFiltersSlice extends UsersFiltersState {
  resetUsersFilters: () => void;
}

export const defaultUsersFiltersState: UsersFiltersState = {
  usersAcquisitionCampaign: [],
  usersActivatedBy: [],
  usersCanScheduleCall: [],
  usersCreatedAt: [],
  usersExcludeDormant: [],
  usersFlags: [],
  usersHasAddress: [],
  usersHasExperience: [],
  usersHasProfileImage: [],
  usersHasWorked: [],
  usersIsBooked: [],
  usersIsFlagged: [],
  usersMarkets: [],
  usersMetabaseQuestions: [],
  usersNotWorkedSince: [],
  usersOnboardedBy: [],
  usersPriorityMax: [],
  usersPriorityMin: [],
  usersRatings: [],
  usersRetailer: [],
  usersRetailerLocation: [],
  usersShiftsCompleteMax: [],
  usersShiftsCompleteMin: [],
  usersStaffMembers: [],
  usersStatus: UserStatusOptions?.filter(o =>
    [UserStatus.ACTIVE].includes(o?.value as UserStatus),
  ),
  usersTags: [],
  usersTagsMissing: [],
  usersTiers: [],
  usersWorkedSince: [],
  usersZipcode: [],
  usersZipcodeMaxDistance: [],
  usersZipcodeMinDistance: [],
};

export const usersFiltersSlice = set => ({
  ...defaultUsersFiltersState,
  resetUsersFilters: () => {
    set(prev => ({ ...prev, ...defaultUsersFiltersState }));
  },
});

export const getParsedWorkersFilters = (
  filters: Partial<UsersFiltersState>,
) => {
  const {
    usersAcquisitionCampaign,
    usersActivatedBy,
    usersCanScheduleCall,
    usersCreatedAt,
    usersExcludeDormant,
    usersFlags,
    usersHasAddress,
    usersHasExperience,
    usersHasProfileImage,
    usersHasWorked,
    usersIsBooked,
    usersIsFlagged,
    usersMarkets,
    usersMetabaseQuestions,
    usersNotWorkedSince,
    usersPriorityMax,
    usersPriorityMin,
    usersShiftsCompleteMax,
    usersShiftsCompleteMin,
    usersStatus,
    usersTags,
    usersTagsMissing,
    usersTiers,
    usersWorkedSince,
    usersZipcode,
    usersZipcodeMaxDistance,
    usersZipcodeMinDistance,
  } = filters || {};

  const graphFilters = {} as fetchUsersProps;

  graphFilters.role = [UserRole.WORKER];

  if (usersAcquisitionCampaign?.length) {
    graphFilters.acquisitionCampaigns = usersAcquisitionCampaign.map(
      campaign => campaign.value,
    );
  }

  if (usersActivatedBy?.length) {
    graphFilters.activatedBy = [usersActivatedBy?.[0]?.value];
  }

  if (usersCanScheduleCall?.length) {
    graphFilters.canScheduleCall = usersCanScheduleCall?.[0]?.value === 'true';
  }

  if (usersCreatedAt?.length) {
    const timePeriod = CreatedFilters[usersCreatedAt?.[0]?.value];
    const parsedDates = dateCreatedParser(timePeriod);
    graphFilters.since = parsedDates.since;
    graphFilters.to = parsedDates.to;
  }

  if (usersExcludeDormant?.length) {
    graphFilters.excludeAttributeDormant =
      usersExcludeDormant?.[0]?.value === 'true';
  }

  if (usersFlags?.length) {
    graphFilters.flags = usersFlags.map(flag => flag.value);
  }

  if (usersHasAddress?.length) {
    graphFilters.hasAddress = usersHasAddress?.[0]?.value === 'true';
  }

  if (usersHasExperience?.length) {
    graphFilters.hasExperience = usersHasExperience?.[0]?.value === 'true';
  }

  if (usersHasProfileImage?.length) {
    graphFilters.hasProfileImage = usersHasProfileImage?.[0]?.value === 'true';
  }

  if (usersHasWorked?.length) {
    graphFilters.hasWorked = usersHasWorked?.[0]?.value === 'true';
  }

  if (usersIsBooked?.length) {
    graphFilters.isBooked = usersIsBooked?.[0]?.value === 'true';
  }

  if (usersIsFlagged?.length) {
    graphFilters.isFlagged = usersIsFlagged?.[0]?.value === 'true';
  }

  if (usersMarkets?.length) {
    graphFilters.markets = usersMarkets.map(market => parseInt(market.value));
  }

  if (usersMetabaseQuestions?.length) {
    graphFilters.metabaseQuestion = usersMetabaseQuestions?.[0]?.value;
  }

  if (usersNotWorkedSince?.length) {
    const timePeriod = TimingFilters[usersNotWorkedSince?.[0]?.value];
    const parsedDates = dateParser(timePeriod);
    graphFilters.hasWorked = false;
    graphFilters.since = parsedDates.since;
    graphFilters.to = parsedDates.to;
  }

  if (usersPriorityMax?.length) {
    graphFilters.reviewPrioritizationAvgMax = parseInt(
      usersPriorityMax?.[0]?.value,
    );
  }

  if (usersPriorityMin?.length) {
    graphFilters.reviewPrioritizationAvgMin = parseInt(
      usersPriorityMin?.[0]?.value,
    );
  }

  if (usersShiftsCompleteMax?.length) {
    graphFilters.shiftsCompletedMax = parseInt(
      usersShiftsCompleteMax?.[0]?.value,
    );
  }

  if (usersShiftsCompleteMin?.length) {
    graphFilters.shiftsCompletedMin = parseInt(
      usersShiftsCompleteMin?.[0]?.value,
    );
  }

  if (usersStatus?.length) {
    graphFilters.status = usersStatus.map(status => status.value as UserStatus);
  }

  if (usersTags?.length) {
    graphFilters.tags = usersTags.map(tag => tag.value);
  }

  if (usersTagsMissing?.length) {
    graphFilters.tagsMissing = usersTagsMissing.map(tag => tag.value);
  }

  if (usersTiers?.length) {
    graphFilters.tier = usersTiers.map(tier => tier.value as WorkerTierOptions);
  }

  if (usersWorkedSince?.length) {
    const timePeriod = TimingFilters[usersWorkedSince?.[0]?.value];
    const parsedDates = dateParser(timePeriod);
    graphFilters.hasWorked = true;
    graphFilters.since = parsedDates.since;
    graphFilters.to = parsedDates.to;
  }

  if (usersZipcode?.length) {
    graphFilters.zipcode = usersZipcode?.[0]?.value;
  }

  if (usersZipcodeMinDistance?.length) {
    graphFilters.zipcodeMinDistance = parseInt(
      usersZipcodeMinDistance?.[0]?.value,
    );
  }

  if (usersZipcodeMaxDistance?.length) {
    graphFilters.zipcodeMaxDistance = parseInt(
      usersZipcodeMaxDistance?.[0]?.value,
    );
  }

  return graphFilters;
};
